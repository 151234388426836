//我的订阅接口
const getServiceListUrl = `/gateway/hc-portal/open/page`;
//申请添加
const applyAddUrl = `/gateway/hc-portal/registerInfo/manage/register`;
//事件通知
const eventNoticeUrl = `/gateway/hc-portal/notify/pageByApp`;
//事件订阅
const subEventNoticeUrl = `/gateway/hc-portal/notify/doRegister`;
//关闭订阅
const closeSubNoticeUrl = `/gateway/hc-portal/notify/unRegister`;
//订阅地址
const callbackSubUrl = `/gateway/hc-portal/notify/registerUrlList`;
//获取可以订阅的小区列表
const authTenantListUrl = `/gateway/hc-portal/client/authTenantList`;
//设置订阅回调
const setCallBackUrl = `/gateway/hc-portal/notify/setRegisterUrl`;
//事件订阅列表
const setSubListUrl = `/gateway/hc-portal/notify/registerInfoPageList`;
//设置事件订阅的开关
const setSubOnUrl = `/gateway/hc-portal/notify/setRegisterInfo`;
//应用列表
const deveApplyUrl = `/gateway/hc-portal/developer/page`;
//门户用户详情
const userDetailUrl = `/gateway/hc-portal/client/detail`;
//测试订阅
const testSubUrl = `/gateway/hc-portal/notify/testNotify`;
//我的空间
const mySpaceUrl = `/gateway/hc-portal/client/queryHouseList`;
//社区列表
const communityListUrl = `/gateway/hc-portal/client/communityList`;
//调整上限
const mastAuthUrl = `/gateway/hc-portal/registerInfo/registerExpandApply`;
// 获取省级上报接口数据
const getDataInterfaceList = `/gateway/hc-portal/open/provinceDataPage`;
//省基础数据上报接口
const updateProvinceData = `/gateway/hc-portal/open/baseDataUpdate`;
//上报接口
const postProvinceData = `/gateway/hc-task/scheduled/task/pushData`;
//省级平台数据上报历史
const getReportData = `/gateway/hc-portal/open/provinceDataPushList`;
//租户及社区编码列表
const getTenantList = `/gateway/hc-portal/szdz/tenantList`;
export {
  getServiceListUrl,
  applyAddUrl,
  eventNoticeUrl,
  subEventNoticeUrl,
  closeSubNoticeUrl,
  authTenantListUrl,
  setCallBackUrl,
  setSubListUrl,
  setSubOnUrl,
  deveApplyUrl,
  userDetailUrl,
  callbackSubUrl,
  testSubUrl,
  mySpaceUrl,
  communityListUrl,
  mastAuthUrl,
  getDataInterfaceList,
  updateProvinceData,
  postProvinceData,
  getReportData,
  getTenantList,
};

import { mapHelper } from "@/utils/common.js";
//是否已订阅
const isHaveRegistered = [
  {
    value: "1",
    label: "是",
  },
  {
    value: "2",
    label: "否",
  },
];
const { map: isHaveRegisteredMap, setOps: isHaveRegisteredOps } =
  mapHelper.setMap(isHaveRegistered);
//审核状态
const auditStatus = [
  {
    value: "0",
    label: "未授权",
  },
  {
    value: "1",
    label: "待审核",
  },
  {
    value: "2",
    label: "审核通过",
  },
  {
    value: "3",
    label: "审核驳回",
  },
];
const { map: auditStatusMap, setOps: auditStatusOps } =
  mapHelper.setMap(auditStatus);
//有效状态
const validStatusList = [
  {
    value: 1,
    label: "启用",
  },
  {
    value: 0,
    label: "禁用",
  },
];
const { map: validStatusListMap, setOps: validStatusListOps } =
  mapHelper.setMap(validStatusList);

//订阅状态
const subStatusList = [
  {
    value: 1,
    label: "未订阅",
  },
  {
    value: 2,
    label: "已订阅",
  },
  {
    value: 3,
    label: "禁用",
  },
];
const { map: subStatusListMap, setOps: subStatusListOps } =
  mapHelper.setMap(subStatusList);
//上限审核状态
const jusgeStatusList = [
  {
    value: 0,
    label: "未申请",
  },
  {
    value: 1,
    label: "待审核",
  },
  {
    value: 2,
    label: "审核通过",
  },
  {
    value: 3,
    label: "审核驳回",
  },
];
const { map: jusgeStatusListMap, setOps: jusgeStatusListOps } =
  mapHelper.setMap(jusgeStatusList);
//  房屋/人筛选
const selectionList = [
  {
    value: 1,
    label: "人员数据",
  },
  {
    value: 2,
    label: "房屋数据",
  },
  {
    value: 3,
    label: "车辆信息",
  },
  {
    value: 11,
    label: "空间信息",
  },
  {
    value: 21,
    label: "内容安全",
  },
  {
    value: 41,
    label: "三维资产",
  },
  {
    value: 42,
    label: "推送数据",
  },


];
const { map: selectionListMap, setOps: selectionListOps } =
  mapHelper.setMap(selectionList);
// 成功or 失败
const isSucceedStatus = [
  {
    value: "1",
    label: "成功",
  },
  {
    value: "0",
    label: "失败",
  },
];
const { map: isSucceedStatusMap, setOps: isSucceedStatusOps } =
  mapHelper.setMap(isSucceedStatus);

//  房屋/人筛选
const labelList = [

  {
    value: 101,
    label: "省级基础数据",
  },
  {
    value: 102,
    label: "省级运营数据",
  },
  {
    value: 103,
    label: "九大场景数据",
  }

];
const { map: labelListMap, setOps: labelListOps } =
  mapHelper.setMap(labelList);
export {
  isHaveRegistered,
  isHaveRegisteredMap,
  isHaveRegisteredOps,
  auditStatus,
  auditStatusMap,
  validStatusList,
  validStatusListMap,
  subStatusListMap,
  selectionListMap,
  selectionListOps,
  isSucceedStatusMap,
  isSucceedStatusOps,
  labelListMap,
  labelListOps
};

<template>
  <div class="houseInfo">
    <v-list
      ref="list"
      :border="false"
      :headers="headers"
      :tableUrl="tableUrl"
      :isIndex="true"
      :searchParam.sync="searchParam"
      :afterReturnList="afterList"
      :isOperateColumn="false"
      :beforeList="beforeList"
    >
      <template #headBtnSlot>
        <v-button text="导入数据" :width="112" @click="toImport"
      /></template>
      <template #searchSlot>
        <v-select
          :options="tenantList"
          v-model="searchParam.tenantIds"
          @change="selectCommunity"
          placeholder="选择社区"
        />
        <v-select
          clearable
          v-if="searchParam.tenantIds"
          :options="communityList"
          v-model="searchParam.spaceId"
          @change="$refs.list.getList()"
          placeholder="选择小区"
        />

        <v-input label="幢" v-model="searchParam.buildingNum" type="number" />
        <v-input label="单元" v-model="searchParam.unitNum" type="number" />
        <v-input label="室" v-model="searchParam.roomNum" type="number" />
      </template>
      <template #operateSlot="scope">
        <v-button
          text="申请添加"
          type="text"
          v-if="scope.row.auditStatus == 3 || scope.row.auditStatus == 0"
          @click="applyAdd(scope.row)"
        />
      </template>
    </v-list>
  </div>
</template>

<script>
import {
  mySpaceUrl,
  applyAddUrl,
  communityListUrl,
  authTenantListUrl,
  getTenantList,
} from "./api.js";

import {
  isHaveRegisteredMap,
  auditStatusMap,
  validStatusListMap,
} from "./map.js";
import { _localStorage } from "@/utils/utils.js";
import { downloadHelper } from "@/utils/func.js";
import popver from "./popver";
export default {
  name: "houseInfo",
  data() {
    let _this = this;
    return {
      communityList: [],
      tenantList: [],
      searchParam: {
        tenantIds: "",
        spaceId: "",
        buildingNum: "",
        unitNum: "",
        roomNum: "",
      },
      tableUrl: mySpaceUrl,
      headers: [
        {
          prop: "communityName",
          label: "小区",
          align: "center",
          show: true,
        },
        {
          prop: "buildingName",
          label: "楼幢",
          align: "center",
          show: true,
        },
        {
          prop: "unitName",
          label: "单元",
          align: "center",
          show: true,
        },

        {
          prop: "roomName",
          label: "房号",
          align: "center",
          show: true,
        },

        {
          prop: "path",
          label: "地址",
          align: "center",
          show: true,
        },
      ],
      tenantId: "",
    };
  },
  created() {
    if (_localStorage.getItem("userInfo")) {
      let userInfo = JSON.parse(_localStorage.getItem("userInfo"));
      this.tenantId = userInfo.tenantId;
    }
  },
  computed: {},
  mounted() {},
  methods: {
    uploadBoard() {
      let url =
        "https://test.zhuneng.cn/imgForManager/static/index/template/houseTemplate.xlsx";
      downloadHelper.downloadByLocation(url);
    },
    async beforeList() {
      await this.getTenantList();
    },
    selectCommunity() {
      this.getCommunityList();
      this.$refs.list.getList();
    },
    async getCommunityList() {
      let params = { tenantId: this.searchParam.tenantIds };
      let res = await this.$axios.get(`${communityListUrl}`, { params });
      if (res.code == 200) {
        this.communityList = [];
        if (res.data && res.data.records) {
          res.data.records.forEach((ele) => {
            let obj = {};
            obj.label = ele.name;
            obj.value = ele.spaceId;
            this.communityList.push(obj);
          });
        }
      }
    },
    //获取社区编码列表
    async getTenantList() {
      const params = {
        tenantId: this.tenantId,
      };
      const res = await this.$axios.post(
        getTenantList,
        this.$qs.stringify(params)
      );
      if (res.code == 200) {
        this.tenantList = [];
        const { data } = res;
        data.forEach((ele) => {
          let obj = {};
          obj.label = ele.tenantName;
          obj.value = ele.tenantId;
          this.tenantList.push(obj);
        });
        this.searchParam.tenantIds = this.tenantList[0].value;
      }
    },
    // async getTenantList() {
    //   let res = await this.$axios.get(`${authTenantListUrl}`);
    //   if (res.code == 200) {
    //     this.tenantList = [];
    //     if (res.data) {
    //       res.data.forEach((ele) => {
    //         let obj = {};
    //         obj.label = ele.tenantName;
    //         obj.value = ele.tenantId;
    //         this.tenantList.push(obj);
    //       });
    //       this.searchParam.tenantIds = this.tenantList[0].value;
    //     }
    //   }
    // },
    afterList(data) {
      data.forEach((ele, index) => {
        ele.id = '!@#$%^&*' + index;
        ele.shelfPopver = {
          component: popver,
        };
      });
      return data;
    },
    toWord() {
      this.$router.push({
        name: "developWord",
        query: {
          path: "static/word/developWord/interface.html",
          fromId: "11235988157386752192",
        },
      });
    },
    applyAdd(row) {
      this.$confirm("是否确定添加?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let params = {
          openId: row.id,
        };
        this.$axios
          .post(`${applyAddUrl}`, this.$qs.stringify(params))
          .then((res) => {
            if (res.code === 200) {
              this.$message({
                message: "操作成功",
                type: "success",
                duration: 500,
              });
              this.$refs.list.search();
            } else {
            }
          });
      });
    },
    toAdd() {
      let _this = this;
      this.$router.push({
        name: "platformManagerForm",
      });
    },
    // 导入
    toImport() {
      this.$router.push({
        name: "peopleHouseImportHouseInfoInfo",
      });
    },
  },
};
</script>
<style lang="less" scoped>
.houseInfo {
  box-sizing: border-box;
  height: 100%;
  /deep/ .v-table .opera-btn {
    width: 100% !important;
    display: flex;
    justify-content: center;
  }
}
</style>
